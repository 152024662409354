import React from "react"
import { Link } from "gatsby"

const ErrorPage = () => {
  return (
    <div>
      <h1>This is the Error Page</h1>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <Link to="/404">Error Page</Link>
        </li>
      </ul>
    </div>
  )
}

export default ErrorPage
